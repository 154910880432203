.content {
  text-align: center;
  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 22px;
    padding: 1rem;
  }

  &__desc {
    color: var(--app-text-60-color);
    font-size: 18px;
    margin-bottom: 59px;
    padding: 1rem;

    &--reports {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      gap: 22px;
      .reportImg {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__btnContent {
    display: flex;
    justify-content: center;
    margin-top: 52px;
    margin-bottom: 65px;
    &--btn {
      border: none;
      width: 200px;
      padding: 0 50px;
      height: 50px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--other-text-color);
      background: var(--app-primary-color);
    }
  }
}

@media screen and (max-width: 900px) {
  .content {
    &__title {
      line-height: 1.5;
      margin-bottom: 12px;
      font-size: 24px;
    }

    &__desc {
      font-size: 16px;
      &--reports {
        grid-template-columns: repeat(3, 1fr);
        padding: 1rem;
      }
    }
  }
}

